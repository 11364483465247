export enum LanguagesEnum {
    EN = 'en',
    AR = 'ar',
  }


 export enum MonthsEnum {
    jan = 'Jan',
    feb = 'Feb',
    mar = 'Mar',
    apr = 'Apr',
    may = 'May',
    jun = 'Jun',
    jul = 'Jul',
    aug = 'Aug',
    sep = 'Sep',
    oct = 'Oct',
    nov = 'Nov',
    dec = 'Dec'
}

export enum ConSumptionTypeEnum {
    commercial = 'Commercial',
    residential = 'Residential'
}


export enum ConSumptionTypeEnumAr {
    commercial = 'تجاري',
    residential = 'سكني'
}


type Region = {
    name: string;
    cities: string[];
  };

export const countries = [
  'Saudi Arabia'
]

export const countriesAr = [
  'المملكة العربية السعودية'
]
  
 export const regions: Region[] = [
    { name: 'Riyadh Region', cities: ['Riyadh', 'Al Dilam'] },
    { name: 'Mecca Region', cities: ['Mecca', 'Jeddah'] },
    { name: 'Eastern Region', cities: ['Dammam', 'Al Khobar'] },
  ];

  export const regionsAr: Region[] = [
    { name: 'الرياض', cities: ['الرياض', 'الدلم'] },
    { name: 'مكة', cities: ['مكة', 'جدة'] },
    { name: 'المنطقة الشرقية', cities: ['الدمام', 'الخبر'] },
  ];


export const phoneCode = [
  '+966',
  '+965',
  '+967',
]