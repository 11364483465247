import React from "react";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { appRoutesObj } from "./app.paths";
import Loading from "./pages/loading";

const NotFoundPage = React.lazy(() => import("./pages/404"));
const LandingPage = React.lazy(() => import("./pages/landing-page"));
const DemoPage = React.lazy(() => import("./pages/demo-page"));
const ContractorPage = React.lazy(() => import("./pages/contractor-page"));
const AboutAsPage = React.lazy(() => import("./pages/about-us-page"));
const PrivacyPolicyPage = React.lazy(
  () => import("./pages/privacy-policy-page")
);

const withSuspense = (WrappedComponent: JSX.Element) => {
  return <Suspense fallback={<Loading />}>{WrappedComponent}</Suspense>;
};

export function AppRouting() {
  return (
    <Suspense
      fallback={<div className="text-primary-200 pt-10">Loading...</div>}
    >
      <BrowserRouter>
        <Routes>
          <Route
            key="notDefined"
            path="*"
            element={withSuspense(<NotFoundPage />)}
          />
          <Route
            key="landingPage"
            path={appRoutesObj.getLandingPagePath()}
            element={withSuspense(<LandingPage />)}
          />
          <Route
            key="demoPage"
            path={appRoutesObj.getDemoPagePath()}
            element={withSuspense(<DemoPage />)}
          />
          <Route
            key="contractorPage"
            path={appRoutesObj.getContractorPagePath()}
            element={withSuspense(<ContractorPage />)}
          />
          <Route
            key="aboutAsPage"
            path={appRoutesObj.getAboutAsPagePath()}
            element={withSuspense(<AboutAsPage />)}
          />
          <Route
            key="privacyPolicyPage"
            path={appRoutesObj.getPrivacyPolicyPath()}
            element={withSuspense(<PrivacyPolicyPage />)}
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
